<template>
  <div class="question-wrapper">
    <h4 v-html="groupQuestion.title" class="text-center">
    </h4>
    <div class="add-btn-wrapper">
      <h3>Danh sách câu hỏi</h3>
      <div class="add-btn">
        <v-btn color="primary" @click="viewDetail()"
        >Tạo câu hỏi mới
        </v-btn>
      </div>
    </div>
    <v-alert v-if="questionsByGroup.length === 0" :value="true" color="warning" icon="priority_high" outlined>
      Chưa có dữ liệu hiển thị
    </v-alert>
    <v-data-table :headers="headers" :items="questionsByGroup" class="elevation-1 custom-size-img">
      <template v-slot:item.content="{ item }">
        <div v-html="item.content"></div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="viewDetail(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {GET_QUESTION_BY_GROUP,DELETE_QUESTION} from "@/store/questions.module";
import {mapGetters} from "vuex";
import {GET_GROUP_QUESTION_DETAIL} from "@/store/group-questions.module";

export default {
  data() {
    return {
      exam_config_id: this.$route.query.exam_config_id,
      group_question_id: this.$route.query.group_question_id,
      headers: [
        {text: "Id", value: "id"},
        {text: "Nội dung câu hỏi", value: "content"},
        {text: "Index", value: "index"},
        {text: "Ngày tạo", value: "created_at"},
        {text: "Thao tác ", value: "actions"},
      ],
    }
  },
  computed: {
    ...mapGetters({
      questionsByGroup: "questionListByGroup",
      groupQuestion: "groupQuestionDetail"
    }),
  },
  async created() {
    await this.fetchQuestionList();
    await this.$store.dispatch(GET_GROUP_QUESTION_DETAIL, {
      group_question_id: this.group_question_id
    });
  },
  methods: {
    fetchQuestionList() {
      this.$store.dispatch(GET_QUESTION_BY_GROUP, {
        group_question_id: this.group_question_id
      });

    },
    viewDetail(item) {
      let routeData = null;
      if (item) {
        routeData = this.$router.resolve({
          path: "/question/detail",
          query: {
            question_id: item.id,
            exam_config_id: this.exam_config_id,
            group_question_id: this.group_question_id,
          },
        });
      } else {
        routeData = this.$router.resolve({
          path: "/question/detail",
          query: {
            exam_config_id: this.exam_config_id,
            group_question_id: this.group_question_id,
          },
        });
      }
      window.open(routeData.href, '_self');
    },
    deleteItem(item) {
      confirm("Bạn có chắc chắn muốn xóa không?") &&
      this.$store
          .dispatch(DELETE_QUESTION, {
            question_id: item.id,
          })
          .then((data) => {
            if (data.status) {
              this.fetchQuestionList()
            }
          });
      this.$router.reload()
    }
  }
}
</script>

<style lang="scss">
.question-wrapper {
  padding: 20px;
}

.add-btn-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 10px;

  .add-btn {
    display: flex;
    justify-content: flex-end;*

    button {
      width: 200px;
    }
  }
}
.custom-size-img {
  img {
    margin-top: 20px;
    width: 300px;
  }
}
</style>
